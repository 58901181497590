import React, { FC } from "react";
import { PageProps } from "gatsby";
import { Tag } from "@/components/Tag";
import Helmet from "react-helmet";
import ReactMarkdown from "react-markdown";
import { SEO } from "@/components/basic/SEO";
import { MainLayout } from "@/layouts/MainLayout";
import { SocialMediaItem } from "@/components/basic/SocialMediaItem";
import { Link } from "@/components/basic/Link";
import { SiteFooterWrapper } from "@/components/basic/SiteFooterWrapper";
import { SiteFooterNewsletterButton } from "@/components/basic/SiteFooterNewsletterButton";
import { socialsPoland } from "@/utils/companySocialMedia";

const BlogPostTemplate: FC<PageProps<
  any,
  GatsbyTypes.Query_contentfulNewsPostArgs
>> = ({ pageContext }) => (
  <MainLayout>
    <Helmet>
      <body className="ltr blog-post-page" />
    </Helmet>
    <SEO
      title={pageContext.title}
      description={pageContext.lead || "Technology for Travel and Tourism"}
      image={
        pageContext.socialMediaPreviewImage
          ? `https:${pageContext.socialMediaPreviewImage.file.url}`
          : "http://anixe.pl/images/preview.jpg"
      }
      keywords={pageContext.tags && pageContext.tags.join(", ")}
    />
    <article className="blog-post l-wrapper clearfix">
      {pageContext.coverImage && (
        <div className="blog-post-hero image-object">
          <img
            className="blog-post-hero-image"
            alt={pageContext.coverImage.title}
            src={pageContext.coverImage.file.url}
          />
        </div>
      )}
      <div className="l-push-top-mobile">
        <div className="blog-socials position-rel">
          <ul className="d-flex-box">
            <SocialMediaItem
              classname="socials-item align-center-tablet l-with-gutter-after-mobile opacity-5"
              alt="facebook"
              url={socialsPoland.facebook}
              iconUrl="/images/social/fb_gray.svg"
              iconWidth="30"
              iconHeight="30"
            />
            <SocialMediaItem
              classname="socials-item align-center-tablet l-with-gutter-after-mobile opacity-5"
              alt="facebook"
              url={socialsPoland.twitter}
              iconUrl="/images/social/twitter_gray.svg"
              iconWidth="30"
              iconHeight="30"
            />
            <SocialMediaItem
              classname="socials-item align-center-tablet l-with-gutter-after-mobile opacity-5"
              alt="facebook"
              url={socialsPoland.linkedin}
              iconUrl="/images/social/linkedin_gray.svg"
              iconWidth="30"
              iconHeight="30"
            />
          </ul>
        </div>
        {pageContext.authorName && pageContext.authorPhoto ? (
          <div className="l-8-cols-tablet l-6-cols-desktop l-island-tablet l-bleed">
            <div className="d-flex-box l-push-bottom">
              <div className="image-object l-with-gutter-after-mobile">
                <img
                  src={`${pageContext.authorPhoto.file.url}?w=100&h=100&fit=thumb&r=999`}
                  alt="author's photo"
                />
              </div>
              <div className="text-upcase">
                <strong className="color-primary-alt text-tight">
                  {pageContext.authorName}
                </strong>
                <span className="text-tight color-faded">
                  {" "}
                  | {pageContext.authorPosition}
                </span>
                <div className="text-small text-tight color-faded">
                  {pageContext.date.toUpperCase()}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="text-small text-tight color-faded l-8-cols-tablet l-6-cols-desktop l-island-tablet l-bleed">
            {pageContext.date.toUpperCase()}
          </div>
        )}
        <ReactMarkdown allowDangerousHtml>
          {pageContext.bodyText.bodyText}
        </ReactMarkdown>
        {pageContext.tags && (
          <p className="tags flex-box flex-box-wrap l-push-top">
            <span className="l-push-after l-half-push-top l-bleed text-tight">
              Tags:
            </span>
            {pageContext.tags.map((tag) => (
              <Tag key={tag} tag={tag} />
            ))}
          </p>
        )}
      </div>
    </article>
    <SiteFooterWrapper>
      <SiteFooterNewsletterButton />
      {/*<Link*/}
      {/*  classname="button button-quaternary-negative-dark button-estimate-project"*/}
      {/*  url="/estimate-project"*/}
      {/*  text="estimate project"*/}
      {/*/>*/}
    </SiteFooterWrapper>
  </MainLayout>
);

export default BlogPostTemplate;
